import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SocialLinks } from "../../constants/social-links";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  SocialLinks = SocialLinks;
}
