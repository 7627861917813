import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LoadingComponent } from "./components/loading/loading.component";
import { FooterComponent } from "./components/footer/footer.component";

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [LoadingComponent, FooterComponent],
  exports: [LoadingComponent, FooterComponent],
})
export class CoreComponentsModule {}
