<footer class="footer background-footer py-4">
    <div class="container-fluid px-0">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="col-md-3 col-sm-6 col-12 pt-2 text-center d-flex align-items-start">
                <div class="mx-auto">
                    <a href="#">
                        <img src="../../../../assets/img/logo-white.svg" alt="" />
                    </a>
                    <div class="pt-5 text-white text-center">
                        <p class="pt-3">Siga-nos</p>
                        <div class="d-flex justify-content-center">
                            <a [href]="SocialLinks.facebook" target="_blank" class="me-2">
                                <img src="../../../../assets/img/facebook.svg" alt="" />
                            </a>
                            <a [href]="SocialLinks.twitter" target="_blank" class="me-2">
                                <img src="../../../../assets/img/x.svg" alt="" />
                            </a>
                            <a [href]="SocialLinks.instagram" target="_blank" class="me-2">
                                <img src="../../../../assets/img/instagram.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-white col-md-2 col-sm-6 col-12 pt-4 pl-30">
                <h6>NAVEGAÇÃO</h6>
                <ul class="list-unstyled pt-4">
                    <li><a class="text-white text-size" href="#">Home</a></li>
                    <li><a class="text-white text-size" href="#">Contratante</a></li>
                    <li><a class="text-white text-size" href="#">Profissional</a></li>
                    <li><a class="text-white text-size" href="#">Quem Usa</a></li>
                    <li><a class="text-white text-size" href="#">Quem Somos</a></li>
                    <li><a class="text-white text-size" href="#">Parceiros</a></li>
                </ul>
            </div>

            <div class="text-white col-md-2 col-sm-6 col-12 pt-4 pl-30">
                <h6>SOBRE NÓS</h6>
                <ul class="list-unstyled pt-4">
                    <li><a class="text-white text-size" href="#">Política de Privacidade</a></li>
                    <li><a class="text-white text-size" href="#">Termos de Uso</a></li>
                    <li><a class="text-white text-size" href="#">Entre em Contato</a></li>
                    <li><a class="text-white text-size" href="#">Perguntas Frequentes</a></li>
                    <li><a class="text-white text-size" href="#">Ajuda/Suporte</a></li>
                </ul>
            </div>

            <div class="text-white col-md-5 col-sm-6 col-12 pt-4 pl-30 pd-30">
                <h6>ATENDIMENTO</h6>
                <p class="text-size pt-4">Segunda a Sexta-Feira das 9h ás 21h<br> Sábado e Domingo das 14h ás 20h,
                    exceto feriados.</p>

                <div class="d-flex flex-wrap gap-3 pt-3 ">
                    <button type="button" class="w-100 w-md-40 text-white text-size btn btn-personalized me-md-2 mb-2 mb-md-0">
                        <img class="icon-img" src="../../../../assets/img/globe.svg" /> PT BR Português
                    </button>
                    <button type="button" class="w-100 w-md-40 text-white text-size btn btn-personalized me-md-2">
                        <img class="icon-img" src="../../../../assets/img/accessibility.svg" /> Acessibilidade
                    </button>
                </div>                    
            </div>
        </div>

        <div class="d-flex flex-wrap pt-4 align-items-center">
            <hr class="color-background w-100">
        </div>

        <div class="d-flex flex-wrap pt-4">
            <div class="col-12 my-auto">
                <p class="text-white text-center text-size-small">© Kamundi e Konatus | Todos os direitos reservados.
                </p>
            </div>
        </div>
    </div>
</footer>